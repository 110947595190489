import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user/user.service';
import { Storage } from '@ionic/storage-angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  displayButton:boolean = true;

  constructor(  
    private router:Router,
    private alertController:AlertController, 
    private nativeStorage:Storage,
    private loadingController:LoadingController,
    private authService:UserService,
    private translate: TranslateService) { }

  ngOnInit() {

  }

  goBack(){
    this.router.navigateByUrl("/split-layout/home");
  }

  async presentLogout() {
    const alert = await this.alertController.create({
      header: this.translate.instant('loginPage.SignOut')+' ?',
      buttons: [
        {
          text: this.translate.instant('loginPage.Cancel'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => { 
          }
        }, {
          text: 'Ok',
          cssClass: 'secondary',
          handler: () => {
            this.logout();
          }
        }
      ]
    });
    await alert.present();
  }


  async logout() {
    const loading = await this.loadingController.create({});
    await loading.present();
    this.authService.logoutUser()
      .then(async (res) => {
        await this.nativeStorage.clear();
        this.router.navigateByUrl("/login");
        loading.dismiss();
      })
      .catch((error) => {
        loading.dismiss();
      })
  }

}
