import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from 'src/app/sharedComponent/header/header.component';
import { AddContratComponent } from 'src/app/Modal/add-contrat/add-contrat.component';



@NgModule({
  declarations: [HeaderComponent  
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HeaderComponent,
  ]
})
export class SharedModule { }
