import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController, ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public navCtrl: NavController,
    public afAuth: AngularFireAuth,
    private toastController:ToastController
  ) {}

  async presentToast(text) {
    const toast = await this.toastController.create({
      message: text,
      duration: 2000,
      color: "danger"
    });
    toast.present();
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise((resolve, reject) => {
        this.afAuth.onAuthStateChanged((user) => {
          if (user) {
            resolve(true);
          } else {
            setTimeout(() => {
              this.navCtrl.navigateBack('login');
            }, 500);
            resolve(false);
          }
        });
      });
  }
}
