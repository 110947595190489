import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})


export class UserService {


  constructor(public db: AngularFireDatabase,public afAuth: AngularFireAuth) { }


  loginUser(email: string, password: string) {    
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }


  getAllResidentByEhpad(idEhpad)
  {
    return this.db.list('Ehpads/'+ idEhpad + '/users').snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() as {} }),
        )
      )
    );
  }


  getUserbyEmail(email: string)
  {
    return this.db.list('users', ref => ref.orderByChild('email').equalTo(email)).snapshotChanges().pipe(
      map(changes => 
        changes.map(c => ({ key: c.payload.key, ...c.payload.val()as {}  }),
        )
      )
    );
  }



  getUserByName(name: string)
  {
    return this.db.list('users', ref => ref.orderByChild('nom').equalTo(name)).snapshotChanges().pipe(
      map(changes => 
        changes.map(c => ({ key: c.payload.key, ...c.payload.val()as {}   }),
        )
      )
    );
  }


  getUserByToken(name: string)
  {
    return this.db.list('users', ref => ref.orderByChild('token').equalTo(name)).snapshotChanges().pipe(
      map(changes => 
        changes.map(c => ({ key: c.payload.key, ...c.payload.val()as {}}),
        )
      )
    );
  }


  userSearchFilter(event, userList){
    const searchTerm = event.target.value;
    let listReturn: any;
    if (!searchTerm) {
      return userList;
    }
    listReturn = userList.filter(t => {
        const bool =
          t.nom.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
          String(t.email).toLowerCase().startsWith(String(searchTerm).toLowerCase()) ||
          t.prenom.toLowerCase().startsWith(searchTerm.toLowerCase());
          return bool;
      }
    );
    return listReturn;
  }


  doctorSearchFilter(event, userList){
    const searchTerm = event.target.value;    
    let listReturn: any;
    if (!searchTerm) {
      return userList;
    }
    listReturn = userList.filter(t => {
        const bool =
          t.nom.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
          t.email.toLowerCase().startsWith(String(searchTerm).toLowerCase()) ||
          t.specialite.toLowerCase().startsWith(searchTerm.toLowerCase());
          return bool;
      }
    );
    return listReturn;
  }




  resetPassword(email: string) {
    return this.afAuth.sendPasswordResetEmail(email);  
  }





  logoutUser():Promise<void> {
    return this.afAuth.signOut();     
  }



  getUserUid() {
    return this.afAuth.currentUser;
  }


  UpdateUser(ehpadID,UserKey, data){
    return this.db.list('Ehpads/'+ehpadID+'/users').update(UserKey,data);   
  }
  
  getUser(UserKey){
   return this.db.object('users/'+UserKey).valueChanges();
  }  


  addUser(ehpadID,data ) { 
    return this.db.list('Ehpads/'+ehpadID+'/users').push(data);
  }

  getResidentbyKey(ehpadID,key)
  {
    return this.db.object('Ehpads/'+ehpadID+'/users/'+key).valueChanges();

  }


  deleteUSer(ehpadID,key)
  {
    return this.db.list('Ehpads/'+ehpadID+'/users').remove(key);  
  }


  //externalDoctor service
  signupDoctor(data) {
    return this.afAuth.createUserWithEmailAndPassword(data.email, data.password).then(value => {
      data.token = value.user.uid;
      console.log(value.user.uid);  
      console.log(data);          
      return value.user.uid
    }).then(res => {
      console.log("after then"+res);      
      this.db.list('doctor').update(res,data);
    });
  }

  addDoctor(idUser,data)
  {
   return  this.db.list('doctor').update(idUser,data);
  }


  getDoctor(UserKey)
  {
    return this.db.object('doctor/'+UserKey).valueChanges();

  }
  updateDoctor(UserKey, data){
      return this.db.list('doctor').update(UserKey,data);   
  }
       

  getListDocteur()
  {
    return this.db.list('doctor').snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() as {} }),
        )
      )
    );


  }


  deleteDoctor(key)
  {
    return this.db.list('doctor').remove(key);  

  }



}
