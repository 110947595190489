import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appMenu]'
})
export class MenuDirective {

  constructor(private el: ElementRef) {
    this.el.nativeElement.style.display = 'hidden';
 }
}
