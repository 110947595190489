import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DataResolverService } from './services/resolver/data-resolver.service';
import { AuthGuard } from './services/user/auth.guard';

const routes: Routes = [

  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
 
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
 
  {
    path: 'split-layout',
    loadChildren: () => import('./split-layout/split-layout.module').then( m => m.SplitLayoutPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'split-user-layout',
    loadChildren: () => import('./split-user-layout/split-user-layout.module').then( m => m.SplitUserLayoutPageModule),
    resolve: {
      myarray:DataResolverService
    }
  }
  /* {
    path: 'dossier-administratif',
    loadChildren: () => import('./administratifUsager/dossier-administratif/dossier-administratif.module').then( m => m.DossierAdministratifPageModule)
  },
  {
    path: 'prestation-social',
    loadChildren: () => import('./administratifUsager/prestation-social/prestation-social.module').then( m => m.PrestationSocialPageModule)
  },
  {
    path: 'entourage',
    loadChildren: () => import('./administratifUsager/entourage/entourage.module').then( m => m.EntouragePageModule)
  },
  {
    path: 'contrat',
    loadChildren: () => import('./administratifUsager/contrat/contrat.module').then( m => m.ContratPageModule)
  },
  {
    path: 'gestion-projet-personnalise',
    loadChildren: () => import('./accompagnementUsager/gestion-projet-personnalise/gestion-projet-personnalise.module').then( m => m.GestionProjetPersonnalisePageModule)
  },
  {
    path: 'gestion-documents',
    loadChildren: () => import('./accompagnementUsager/gestion-documents/gestion-documents.module').then( m => m.GestionDocumentsPageModule)
  },
  {
    path: 'gestion-evaluation',
    loadChildren: () => import('./accompagnementUsager/gestion-evaluation/gestion-evaluation.module').then( m => m.GestionEvaluationPageModule)
  },
  {
    path: 'gestion-parcours-sejour',
    loadChildren: () => import('./accompagnementUsager/gestion-parcours-sejour/gestion-parcours-sejour.module').then( m => m.GestionParcoursSejourPageModule)
  },
  {
    path: 'coordination-planification-activite',
    loadChildren: () => import('./acteurCoordination/coordination-planification-activite/coordination-planification-activite.module').then( m => m.CoordinationPlanificationActivitePageModule)
  },
  {
    path: 'gestion-rdv-planing',
    loadChildren: () => import('./acteurCoordination/gestion-rdv-planing/gestion-rdv-planing.module').then( m => m.GestionRdvPlaningPageModule)
  }, */
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
