import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController, MenuController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './services/user/user.service';
import { Storage } from '@ionic/storage-angular';
import { DataService } from './services/data/data.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public appPagesAdmins = [

    { title: 'Dossier Médical', url: '/list-medical-folder', icon: 'folder' }, 
    {
      title: 'Intervenant externe',
      url: '/external-doctor',
      icon: 'construct'
    },
  ]


  public doctorMenu = [
    { title: 'Mes Patients', url: '/doctor-dashboard', icon: 'folder' },    
    {
      title: 'Mes Consultations',
      url: '/historique-consultation-doctor',
      icon: 'link'
    },
  ]

  public communications = [ 
    {
      title: 'Cadres',
      url: '/cadres',
      icon: 'albums'
    },
    {
      title: 'Menu de la semaine',
      url: '/menus',
      icon: 'restaurant'
    }, 
    {
      title: 'Évènement',
      url: '/event',
      icon: 'trail-sign'
    },
    {
      title: 'Message pratique',
      url: '/conseils',
      icon: 'chatbox'
    },  
    {
      title: 'Message préventif',
      url: '/message-preventif',
      icon: 'chatbubbles'
    },  
    {
      title: 'Actualité',
      url: '/actualite',
      icon: 'alert-circle'
    },  
    { title: 'Alertes résidents',
      url: '/alertes',
      icon: 'alert' 
    }, 
  ]

  public appPages1 = [
    { title: 'Admission de l‘usager', url: '/admission', icon: 'folder' },      
    {
      title: 'Dossiers Résident',
      url: '/residents',
      icon: 'person'
    }/* ,
    {
      title: 'Parcours de l’usager',
      url: '/parcours-usager',
      icon: 'albums'
    }, */
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];


  //menu User

  appPages = [
    {
        title: 'Dossier médical',
        url: '/home/landing',
        icon: ''
    },
    {
        title: 'suivi de consultation',
        url: '/profile',
        icon: ''
    },
    {
        title: 'Descriptions',
        url: '/settings/set-location',
        icon: ''
    },
    {
        title: "Plan de soins",
        url: "/promotions",
        icon: ''
    },
    {
        title: "Constantes",
        url: "/promotions",
        icon: ''
    },
    {
        title: "Chutes",
        url: "/promotions",
        icon: ''
    },
    {
        title: "Laboratoires",
        url: "/promotions",
        icon: ''
    }
    ,
    {
        title: "Observations médicales",
        url: "/promotions",
        icon: ''
    }
];
accompagnementUsager = [
  {
      title: 'Gestion du projet personnalisé',
      url: 'gestion-projet-personnalise',
      icon: ''
  },
  {
      title: 'Gestion des documents',
      url: '/gestion-documents',
      icon: ''
  },
  {
      title: 'Gestion de l‘evaluation multi',
      url: '/gestion-evaluation',
      icon: ''
  },

];
admissionUsager = [
  {
      title: 'Accueil de la personne',
      url: '/detail-resident',
      icon: ''
  },
  {
      title: 'Info/ recueil des consentements',
      url: '/receuil-consentements',
      icon: ''
  },
  {
      title: 'Evaluation de la personne',
      url: '/evaluation-personne',
      icon: ''
  },
  {
      title: 'Suivi des orientations et des notifications',
      url: '/suivi-orientation',
      icon: ''
  }
];
gestionAdminsitratif = [
  {
    title: 'Dossier Adminsitratif',
    url: 'dossier-administratif',
    icon: ''
},
{
    title: 'Prestation social',
    url: '/prestation-social',
    icon: ''
},
{
    title: 'Entourage',
    url: '/entourage',
    icon: ''
},
{
    title: 'Contrat',
    url: '/contrat',
    icon: ''
}
];

coordinationActor = [
  {
      title: 'Coordination et planification des activités',
      url: '/coordination-planification-activite',
      icon: ''
  },
  {
      title: 'Gestion RDV et plannig',
      url: '/gestion-rdv-planing',
      icon: ''
  },
]


  constructor(private alertController:AlertController,
    private nativeStorage:Storage,
    private menu:MenuController,
    private loadingController:LoadingController,
    private dataService:DataService,
    private authService:UserService,
    private router:Router, 
    private translate: TranslateService){
        /**set Default Lang  Obligatoire pour que la traduction fonctionne */
        translate.addLangs(['en', 'fr']);
        const browserLang = translate.getBrowserLang();
        translate.setDefaultLang(browserLang);
   }

  ngOnInit() {
    
 

  }

  

  
  goTo()
  {
    this.router.navigateByUrl('/folder/Outbox');
  }

    async presentLogout() {
    const alert = await this.alertController.create({
      header: this.translate.instant('loginPage.SignOut')+' ?',
      buttons: [
        {
          text: this.translate.instant('loginPage.Cancel'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => { 
          }
        }, {
          text: 'Ok',
          cssClass: 'secondary',
          handler: () => {
            this.logout();
          }
        }
      ]
    });
    await alert.present();
  }


  async logout() {
    const loading = await this.loadingController.create({});
    await loading.present();
    this.authService.logoutUser()
      .then(async (res) => {
        await this.nativeStorage.clear();
        this.router.navigateByUrl("/login");
        loading.dismiss();
      })
      .catch((error) => {
        loading.dismiss();
      })
  }

  onMenuOpen()
  {
   
    

  }


}
