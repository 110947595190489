import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { DataService } from '../data/data.service';
import { UserService } from '../user/user.service';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class DataResolverService implements Resolve<any> {

  constructor(private dataService:DataService,private router:Router,private userService:UserService,private nativeStorage:Storage) {    }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let userInfo = this.dataService.getData();
    console.log(userInfo);
    if(userInfo)
    {
      return userInfo
    }
    else{
        this.logout();
    }
  }


  async logout() {
   
    this.userService.logoutUser()
      .then(async (res) => {
        this.router.navigateByUrl("/login");
      })
     
  }
}
