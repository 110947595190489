import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private data:any;
  private statusAlerte:any;
  private medicalFolder:any;
  private alert: any;
  private isDoctor :  boolean = false ;   
  private rubrique:any;

  
 constructor() { }

 setStatusDoctor()
 {
  this.isDoctor = true
 }

 getStatusDoctor()
 {
   return this.isDoctor 
 }


 async setMedicalFolder(medicalFolder)
{
  this.medicalFolder = medicalFolder
}

async setStatusAlertes(value)
{
  this.statusAlerte = value
}


getStatusAlertes()
{
  return this.statusAlerte
}



getMedicalFolder()
{
  return this.medicalFolder
}

async setData(data)
 {
      this.data = data
 }


 async setalert(alert)
 {
      this.alert = alert
 }

 getAlert(){
  return this.alert
 }

  getData()
  {
    return this.data
  }

async setRubrique(rubrique)
{
  this.rubrique = rubrique;

}

getRubrique()
{
  return this.rubrique
}

}
